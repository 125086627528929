<template>
  <v-dialog v-model="dialog" scrollable max-width="600" @click:outside="reset">
    <v-card>
      <v-card-title class="headline">
        {{ isEditing ? "Edit" : "Add a new" }} property
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4" ref="formWrapper">
        <v-form @submit.prevent="save" method="post" id="property-form">
          <v-text-field
            label="Property Address"
            v-model="fields.property_address"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_address')"
            :error-messages="errors['property_address']"
          ></v-text-field>

          <v-text-field
            label="Number Bedrooms *"
            v-model="fields.number_bedrooms"
            type="number"
            step="1"
            min="0"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('number_bedrooms')"
            :error-messages="errors['number_bedrooms']"
          ></v-text-field>

          <v-text-field
            label="EPC Rating"
            v-model="fields.epc"
            type="text"
            max="3"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('epc')"
            :error-messages="errors['epc']"
          ></v-text-field>

          <v-text-field
            label="Current Rental Amount"
            v-model="fields.current_rental_amount"
            type="text"
            max="20"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('current_rental_amount')"
            :error-messages="errors['current_rental_amount']"
          ></v-text-field>

          <v-text-field
            label="Property Valuation"
            v-model="fields.property_valuation"
            type="text"
            max="20"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_valuation')"
            :error-messages="errors['property_valuation']"
          ></v-text-field>

          <v-text-field
            label="Asking Price"
            v-model="fields.asking_price"
            type="text"
            max="20"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('asking_price')"
            :error-messages="errors['asking_price']"
          ></v-text-field>

          <v-text-field
            label="Yield"
            max="100"
            v-model="fields.yield"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('yield')"
            :error-messages="errors['yield']"
          ></v-text-field>

          <v-textarea
            label="Short Description"
            v-model="fields.short_description"
            rows="6"
            outlined
            :error="errors.hasOwnProperty('short_description')"
            :error-messages="errors['short_description']"
          ></v-textarea>

          <v-file-input
            label="Photo"
            v-model="fields.photo"
            placeholder="Choose a File"
            @change="imageHasChanged()"
            outlined
            :error="errors.hasOwnProperty('photo')"
            :error-messages="errors['photo']"
          ></v-file-input>

          <v-switch
            label="Draft"
            v-model="fields.draft"
            inset
            :error="errors.hasOwnProperty('draft')"
            :error-messages="errors['draft']"
          ></v-switch>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="reset()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="property-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      property: {},
      fields: {
        property_address: null,
        number_bedrooms: null,
        epc: null,
        current_rental_amount: null,
        property_valuation: null,
        asking_price: null,
        yield: null,
        short_description: null,
        photo: null,
        draft: true,
        image_has_changed: false,
      },
      positions: ["READY", "IN RENTED"],
      errors: {},
    };
  },

  methods: {
    imageHasChanged() {
      this.fields.image_has_changed = true;
    },

    open(property = null) {
      if (property !== null) {
        this.property = property;
        this.isEditing = true;

        this.fields.property_address = property.property_address;
        this.fields.number_bedrooms = property.number_bedrooms;
        this.fields.epc = property.epc;
        this.fields.current_rental_amount = property.current_rental_amount;
        this.fields.property_valuation = property.property_valuation;
        this.fields.asking_price = property.asking_price;
        this.fields.yield = property.yield;
        this.fields.short_description = property.short_description;

        this.fields.photo = property.photo
          ? new File([property.photo], property.photo)
          : null;

        this.fields.draft = property.draft;
      }

      this.dialog = true;
    },

    save() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.propertyId = this.property.id;
      }

      this.$store
        .dispatch("easylets/salesStore/saveProperty", payload)
        .then(() => {
          this.reset();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    reset() {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.property = {};
      this.fields = {
        property_address: null,
        number_bedrooms: null,
        epc: null,
        current_rental_amount: null,
        property_valuation: null,
        asking_price: null,
        yield: null,
        short_description: null,
        photo: null,
        image_has_changed: false,
        draft: true,
      };
      this.$refs.formWrapper.scrollTop = 0;
    },
  },
};
</script>
