<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Sales Properties</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Properties"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.salesDialog.open()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Sales Property</span>
            </v-tooltip>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archived Properties</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="properties"
        class="mt-4 mb-5"
        no-data-text="No propertys found"
      >
        <template v-slot:item.property_address="{ item }">
          {{ item.property_address }}
          <span class="green--text" v-if="item.draft">- Draft</span>
        </template>

        <template v-slot:item.draft="{ item }">
          <v-switch v-model="item.draft" @click="openSwitch(item)"></v-switch>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.salesDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>

    <v-dialog v-model="deleteProperty.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Property</v-card-title>
        <v-card-text>Are you sure you wish to archive property?</v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteProperty.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <sales-dialog ref="salesDialog" />
  </div>
</template>

<script>
import SalesDialog from "./components/SalesPropertyDialog.vue";

export default {
  components: {
    SalesDialog,
  },

  data() {
    return {
      dialog: false,
      loading: false,
      isEditing: false,
      property: {},
      fields: {
        property_address: null,
        number_bedrooms: null,
        epc: null,
        current_rental_amount: null,
        property_valuation: null,
        asking_price: null,
        yield: null,
        short_description: null,
        photo: null,
        draft: true,
      },
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Application Properties",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "Property", value: "property_address" },
        { text: "NumberBedrooms", value: "number_bedrooms" },
        { text: "EPC", value: "epc" },
        { text: "Current Rental Amount", value: "current_rental_amount" },
        { text: "Property Valuation", value: "property_valuation" },
        { text: "Draft", value: "draft" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      deleteProperty: {
        dialog: false,
        property: {},
        loading: false,
      },
    };
  },

  computed: {
    properties() {
      let properties = this.$store.getters["easylets/salesStore/all"];

      if (this.searchTerm !== "") {
        properties = properties.filter((c) => {
          const property_address = c.property_address.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return property_address.includes(searchTerm);
        });
      }

      return properties;
    },
  },

  methods: {
    openSwitch(property) {
      this.loading = true;
      this.errors = {};

      this.fields.draft = property.draft ? 1 : 0;

      let payload = {
        appId: this.$route.params.id,
        fields: this.fields,
        propertyId: property.id,
      };

      this.$store
        .dispatch("easylets/salesStore/savePropertyVisible", payload)
        .then(() => {})
        .catch();
    },

    openDelete(property) {
      this.deleteProperty.property = property;
      this.deleteProperty.dialog = true;
    },

    resetDelete() {
      this.deleteProperty.dialog = false;
      this.deleteProperty.property = {};
      this.deleteProperty.loading = false;
    },

    saveDelete() {
      this.deleteProperty.loading = true;

      this.$store
        .dispatch("easylets/salesStore/deleteProperty", {
          appId: this.$route.params.id,
          propertyId: this.deleteProperty.property.id,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteProperty.loading = false;
        });
    },
  },
};
</script>
